:root {
    --color-text-primary: #fff;
    --color-text-primary-inverted: #141416;

    --primary-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    --mono-font-family: "Roboto Mono", "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
    --font-family-text: "Nunito Sans", var(--primary-font-family);
    --title-font-family: "Bebas Neue", var(--primary-font-family);

    --solice-card-gradient: linear-gradient(115deg, rgb(38, 10, 120, 0.9) 0%, rgba(16, 4, 65, 0.9) 82%);
    --solice-card-border: 2px solid #321998;
    --solice-card-box-shadow: 0 2px 4px #09012A;
    --solice-card-border-highlight: 2px solid #4526c3;

    --container-width: 1270px;

    @media (max-width: 1440px) {
        --container-width: 940px;
    }

    @media (max-width: 940px) {
        --container-width: 680px;
    }

    @media (max-width: 680px) {
        --container-width: 480px;
    }

    @media (max-width: 480px) {
        --container-width: 320px;
    }
}